import React from 'react';
import Text from 'mineral-ui/Text';

const Header = () => (
  <React.Fragment>
    <Text align="center" element="h1">
      valentino.
    </Text>
  </React.Fragment>
);
export default Header;
